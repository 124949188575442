import { useStaticQuery, graphql } from "gatsby"
export const useRemoteImg = () => {
  const allImg = useStaticQuery(
    graphql`
      query allRemoteImg {
        allImage {
            edges {
                node {
                    image {
                        base
                        childImageSharp {
                            fixed {
                                src
                            }
                            fluid(maxWidth: 1920) {
                                ...GatsbyImageSharpFluid
                                # ...GatsbyImageSharpFluid_withWebp
                                # ...GatsbyImageSharpFluid_tracedSVG
                            }
                        }
                    }
                    id
                }
            }
        }
      }
    `
  )
  return allImg
}