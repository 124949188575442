import React, {useState} from "react"
import Footer from '../components/footer'
import BtnUp from '../components/btn_up'
import NavBar from '../components/nav-bar'
import { Body } from '../components/common'
import NlContentSec from '../components/nl_content'
import NlSign from '../components/nl_sign'
import ConsulSign from '../components/consul_sign'
import { getShowNL, setShowNL } from "../cookies/nl_sign_cookie"
import { getMenuItem, setMenuItem } from "../cookies/submenu_cookie"
// import scrollTo from 'gatsby-plugin-smoothscroll'
import { navigate } from "gatsby"




export default ({data}) =>{ 
    const [consultation, setConsultation] = useState(false);

    const openConsulSign = (evt) =>{
        setConsultation(true)
    }

    if(!getShowNL){
        setShowNL({'show': true})
    }
    
    const changeService = (evt) =>{
        var menu_temp = getMenuItem()

        if(evt.target.id === 'true'){
            menu_temp['item'] = evt.target.name
            setMenuItem(menu_temp)
            navigate('/#is')
            // setService(evt.target.name)
        }else{
            navigate('/per_diem')
        }
    }

    return(
        <Body>
            <NavBar pickService={changeService} iService={0} perDiem={0} abs={true} isSticky={false} consulOpen={openConsulSign}/>
            <NavBar pickService={changeService} iService={0} perDiem={0} isSticky={true} consulOpen={openConsulSign}/>
            <div style={{height:'100px'}}></div>
            <NlSign open={getShowNL()['show']}/>
            <ConsulSign open={consultation} onClose={()=>setConsultation(false)}/>
            <NlContentSec/>
            <Footer/>
            <BtnUp/>
        </Body>
    )
}
