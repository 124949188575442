import React, { useState, useEffect} from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import Img from "gatsby-image"
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineRight, AiOutlineLeft } from 'react-icons/ai';
import { GoSearch } from 'react-icons/go';
import { MdViewList, MdViewAgenda } from 'react-icons/md';
import { navigate } from "gatsby"

import { useImagesNewsletter } from "../hooks/use-images-newsletter"
import { useNewsletter } from "../hooks/use-newsletters"
import { useRemoteImg } from "../hooks/use-remote-images"
// import { getUser } from "../services/auth"





const Bg = styled.div`
   width: 100%;
   h1{
       text-align: center;
   }
   p{
       color: #888;
       text-align: center;
   }
`

const WildcardContainer = styled.div`
   width: 30vw;
   cursor: pointer;
   float: left;
   margin-right: 0.5vw;
   margin-left: 0.5vw;
`

const WildcardImgContainer = styled.div`
   height: 22vw;
   overflow: hidden;
`

const WildcardCaption = styled.div`
   padding: 1.5rem;
   background-color: #f6f6f6;

   p{
       text-align: center;
       margin-bottom: 0.5rem;
   }
`

const WildcardsContainer = styled.div`
    width: 93vw;
    margin: auto;
    overflow: auto;
`

const ControlsContainer = styled.div`
    width: max-content;
    margin: auto;
`

const BlueButton = styled.button`
    display: inline;
    margin-top: -50rem;
    font-size: 1.5rem;
    border-radius: 3rem;
    background-color: #214c9b;
    color: white;
    padding: 0.8rem 1.5rem;
    cursor: pointer;
    margin-right: 1rem;
    
    border: none;
    outline: none;

`

const DesktopComp = styled.div`
    display: block;                            
    @media (max-width: 600px) {
        display: none;                            
    }
`

const MobileComp = styled.div`
    display: none;                            
    @media (max-width: 600px) {
        display: block;                            
    }
`
const SearchContainer = styled.div`
    border: 2px solid #ddd;

    border-top-style: solid;
    border-right-style: none;
    border-left-style: none;
    border-bottom-style: solid;

    padding: 1rem;
    width: 95%;
    margin: auto;
    margin-top: 3rem;

    input{
        position: absolute;
        top: 0;
        font-size: 1.8rem;
        color: #333;
        border: none;
        min-width: 200px;
    }
`

const DisplayContainer = styled.div`
`
const Divider = styled.button`
    border: 2px solid #f6f6f6;
    border-top-style: none;
    border-right-style: none;
    border-left-style: none;
    border-bottom-style: solid;
    
    outline: 0;
    background-color: transparent;
    overflow: auto;
    padding: 2rem 1rem;
    width: 95%;
    margin: auto;
    cursor: pointer;
`
const UnstyledBtn = styled.button`
    border: none;
    outline: 0;
    padding: 0;
    display: inline-block;
    float: right;
    background-color: transparent;
`
const ListCont = styled.div`
    ${p => p.show ? css`
        display: block;
    ` : css`
        display: none;
    `}
`

const ImgCont = styled.div`
    padding-top: 2rem;

    ${p => p.show ? css`
    display: block;
    ` : css`
    display: none;
    `}
`


let Images =  {}
let Nl_arr = {}
let filter_arr = []
let display_arr = []
let ordered_arr = []
let current_page = 1
let total_pages = 0
let prev_page = 0
let mob_index = 0
// let m_display_arr = []
let nl_image_arr = []
let nl_image_id = []

let remoteImg = []


const SearchComponent = (props) =>{
    var color = '#bbb'
    var color2 = '#0099df'
    
    if(props.displayMode){
        color = '#0099df'
        color2 = '#bbb'
    }
    
    return(
        <SearchContainer>
            <label htmlFor="search"><GoSearch size={32} style={{color:'#bbb', display:'inline-block'}}/></label>
            <div style={{display:'inline-block', marginTop: '-0.9rem', marginLeft:'2rem', position:'relative', width: '200px', height:'3rem'}}><input type="text" id="search" onChange={props.filterFcn}/></div>
            <UnstyledBtn onClick={()=>props.changeMode(true)} style={{ marginLeft:'1rem', marginTop:'0.3rem', cursor:'pointer'}}><MdViewAgenda size={29} style={{color: color}}/></UnstyledBtn>
            <UnstyledBtn onClick={()=>props.changeMode(false)}><MdViewList size={36} style={{color: color2, cursor:'pointer'}}/></UnstyledBtn>
        </SearchContainer>
    )
}



const DisplayComponent = (props) =>{
    let fullArr = []
    // var slug = ''
    
    for(var i=0; i<props.MdisplayArr.length; i++){
        for(var j=0; j<props.ImgId.length; j++){
            if(props.MdisplayArr[i].node.id == props.ImgId[j].nl_id){
                // for (var k = 0; k < props.data.newsletters.edges.length; k++) {
                //     // console.log('id: ', props.data.newsletters.edges[i].node.id)
                //     if(props.data.newsletters.edges[k].node.id == props.MdisplayArr[i].node.id){
                //         slug = props.data.newsletters.edges[i].node.fields.slug
                //     }
                // }
                if(props.ImgId[j].img_id == 0){
                    fullArr.push(
                        {
                            'nl_id': props.MdisplayArr[i].node.id, 
                            'content': props.MdisplayArr[i].node.content, 
                            'img_id': 0,
                            'img_data': null,
                            'img_name': 'default',
                            'slug': props.MdisplayArr[i].node.fields.slug,
                        }
                    )
                }else{
                    for(var k=0; k<props.Images.length; k++){
                        if(props.ImgId[j].img_id == props.Images[k].id){
                            fullArr.push(
                                {
                                    'nl_id': props.MdisplayArr[i].node.id, 
                                    'content': props.MdisplayArr[i].node.content, 
                                    'img_id': props.Images[k].id,
                                    // 'img_data': props.Images[k].data,
                                    'img_data': props.Images[k].fluid,
                                    'img_name': props.Images[k].name,
                                    'slug': props.MdisplayArr[i].node.fields.slug,
                                }
                            )
                        }
                    }
                }
                // slug = ''
            }
        }
    }

    return(
        <DisplayContainer>
            <ListCont show={!props.displayMode}>
                {props.displayArr.map((element, index) => (
                    <Divider onClick={()=>navigate(element.node.fields.slug)} key={index} id={element.node.id}>
                        <span style={{float:'left', color:'#666'}}><h3 id={element.node.id}>{JSON.parse(element.node.content)[0].arg1}</h3></span>
                        <span style={{float:'right', color:'#666', fontSize:'1.2rem'}} id={element.node.id}>{JSON.parse(element.node.content)[0].arg2}</span>
                        <br></br>
                        <br></br>
                        <p style={{float:'left', color:'#888'}} id={element.node.id}>{JSON.parse(element.node.content)[1].arg1}</p>
                    </Divider>
                ))}
            </ListCont>

            <ImgCont show={props.displayMode}>
            {fullArr.map((element, index) => (
                    <div onClick={()=>navigate(element.slug)} id={element.id} key={index} style={{margin:'auto', marginBottom:'2rem', width: '95%', border: 'none', backgroundColor:'transparent', width:'100%', outline:'0', cursor:'pointer'}}>
                        <WildcardImgContainer style={{height:'20rem'}} id={element.id}>
                        {element.img_id != 0 ? (
                            <>
                                {/* <img id={element.id} src={element.img_data} alt={element.img_name}/> */}
                                <Img id={element.id} fluid={element.img_data} />
                            </>
                                ) : 
                            <>
                                <Img id={element.id} fluid={Images['passport_bag'].childImageSharp.fluid} />
                            </>
                        }
                            
                        </WildcardImgContainer>
                        <WildcardCaption>
                            <p style={{fontSize:'1.8rem', color:'#333'}} id={element.id}>{JSON.parse(element.content)[0].arg1}</p>
                            <p id={element.id}>{JSON.parse(element.content)[0].arg2}</p>
                        </WildcardCaption>
                    </div>
                ))}
            </ImgCont>

            <div style={{margin:'auto', width:'95%', marginTop:'5rem'}}>
                <div style={{border:'2px solid #ddd', borderBottomStyle: 'none', marginBottom:'-1.8rem', zIndex:'-1'}}></div>                
                <BlueButton onClick={props.loadMore} style={{backgroundColor:'#0099df', fontSize:'2rem', padding:'0rem 1rem', margin:'auto', display:'block', zIndex:'2'}}>+</BlueButton>
            </div>
        </DisplayContainer>
    )
}

const DesktopDisplayComponent = (props) =>{
    let fullArr = []
    let subArr = []
    var index = 0

    // index = Math.pow(3, props.page-1) - 1
    index = 3*(props.page-1)

    // console.log('this is index: ', index)
    // console.log('this is page: ', props.page)

    for (var l = index; l < index+3; l++) {
        if(l < props.displayArr.length){
            subArr.push(props.displayArr[l])
        }
    }
   
    for(var i=0; i<subArr.length; i++){
        for(var j=0; j<props.ImgId.length; j++){
            if(subArr[i].node.id == props.ImgId[j].nl_id){
                if(props.ImgId[j].img_id == 0){
                    fullArr.push(
                        {
                            'nl_id': subArr[i].node.id, 
                            'content': subArr[i].node.content, 
                            'img_id': 0,
                            'img_data': null,
                            'img_name': 'default',
                            'slug': subArr[i].node.fields.slug,
                        }
                    )
                }else{
                    for(var k=0; k<props.Images.length; k++){
                        if(props.ImgId[j].img_id == props.Images[k].id){
                            fullArr.push(
                                {
                                    'nl_id': subArr[i].node.id, 
                                    'content': subArr[i].node.content, 
                                    'img_id': props.Images[k].id,
                                    'img_data': props.Images[k].fluid,
                                    'img_name': props.Images[k].name,
                                    'slug': subArr[i].node.fields.slug,
                                }
                            )
                        }
                    }
                }
            }
        }
    }
    return(
        <>
            <WildcardsContainer>
                {fullArr.map((element, index) => (
                    <WildcardContainer onClick={()=>navigate(element.slug)} id={element.id} key={index}>
                        <WildcardImgContainer id={element.id}>
                            {element.img_id != 0 ? (
                                <>
                                    {/* <img id={element.id} src={element.img_data} alt={element.img_name}/> */}
                                    <Img id={element.id} style={{width:'34vw', marginLeft: '-3vw', minHeight: '22vw'}} fluid={element.img_data} />
                                </>
                                    ) : 
                                <>
                                    <Img id={element.id} style={{width:'34vw', marginLeft: '-3vw'}} fluid={Images['passport_bag'].childImageSharp.fluid} />
                                </>
                            }
                        </WildcardImgContainer>
                        <WildcardCaption>
                            <p style={{fontSize:'1.8rem', color:'#333'}} id={element.id}>{JSON.parse(element.content)[0].arg1}</p>
                            <p id={element.id}>{JSON.parse(element.content)[0].arg2}</p>
                        </WildcardCaption>
                    </WildcardContainer>
                ))}
            </WildcardsContainer>
            <br></br>
            <ControlsContainer>
                <AiOutlineDoubleLeft onClick={()=>props.changePage('first')} size={30} style={{color:'#888', cursor: 'pointer', marginRight:'1rem', paddingTop:'1rem'}}/>
                <AiOutlineLeft onClick={()=>props.changePage('previous')} size={30} style={{color:'#888', cursor: 'pointer', marginRight:'1rem', paddingTop:'1rem'}}/>
                <BlueButton>{props.page}</BlueButton>
                <AiOutlineRight onClick={()=>props.changePage('next')} size={30} style={{color:'#888', cursor: 'pointer', marginRight:'1rem', paddingTop:'1rem'}}/>
                <AiOutlineDoubleRight onClick={()=>props.changePage('last')} size={30} style={{color:'#888', paddingTop:'1rem', cursor: 'pointer'}}/>
            </ControlsContainer>
        </>
    )
}

const NlContentSec = (props) =>{
    const [DisplayMode, setDisplayMode] = useState(false);
    const [rendering, setRendering] = useState(false);

   
    Images = useImagesNewsletter()
    remoteImg = useRemoteImg()
    // console.log('This is rempteImg: ', remoteImg.allImage.edges)
    Nl_arr = useNewsletter().allNewsletter.edges
    Nl_arr.sort(function(a, b){return b.node.id - a.node.id});
   

    const getNLImgId = () =>{
        nl_image_id = []
        var json_temp = {}
        var picked = false
        // it was display_arr before
        for(var i=0; i<Nl_arr.length; i++){
            json_temp = JSON.parse(Nl_arr[i].node.content)
            picked = false
            for(var j=0; j<json_temp.length; j++){
                if(!picked){
                    if(json_temp[j].name == 'picture'){
                        nl_image_id.push({'nl_id':Nl_arr[i].node.id, 'img_id':json_temp[j].arg2})
                        picked = true
                    }
                }
            }
            if(!picked){
                nl_image_id.push({'nl_id':Nl_arr[i].node.id, 'img_id':0})
            }
        }
        // console.log('This are the ids for tha pictures in NL: ', nl_image_id)

    }

    const getNLImages = async() =>{
        var repeated = false

        for(var i=0; i<nl_image_id.length; i++){
            if(nl_image_id[i]['img_id'] != 0){
                for(var j=0; j<nl_image_arr.length; j++){
                    if(nl_image_arr[j]['id'] == nl_image_id[i]['img_id']){
                        repeated = true 
                    }
                }
                if(!repeated && nl_image_id[i]['img_id'] != 0){
                    // console.log('This are the ids requested: ', nl_image_id[i]['img_id'])
                    // await fetch(`https://feodorofflaw-api.herokuapp.com/api/v1/get_img`,
                    // {method: "POST", mode: "cors", cache: "no-cache", credentials: "omit", headers: {"Content-Type": "application/json"}, redirect: "follow", referrer: "client", body: JSON.stringify({"email": getUser().email, "password": getUser().hash, 'img_id': nl_image_id[i]['img_id']})})
                    // .then(res => res.json())
                    // .then(json => {
                    //     // console.log('This is the server response: ', json[0])
                    //     // setData(JSON.parse(json[0].content))
                    //     nl_image_arr.push({'id': nl_image_id[i]['img_id'], 'name': json.img_name, 'data': json.format +','+ json.encode})
                    //     // setRendered(!Rendered)        
                    // });
                    for (var k = 0; k < remoteImg.allImage.edges.length; k++) {
                        if( nl_image_id[i]['img_id'] == remoteImg.allImage.edges[k].node.id){
                            nl_image_arr.push({'id': nl_image_id[i]['img_id'], 'name': remoteImg.allImage.edges[k].node.image.base, 'data': remoteImg.allImage.edges[k].node.image.childImageSharp.fixed.src, 'fluid':  remoteImg.allImage.edges[k].node.image.childImageSharp.fluid})
                        }   
                    }

                }else{
                    repeated = false
                }

            }
        }
        // console.log('This are the images for NL: ',nl_image_arr)

    }

    const buttonOptions = {
        'first': function (page, total_pages) {
            return 1
        },

        'last': function (page, total_pages) {
            return total_pages
        },

        'previous': function (page, total_pages) {
            if(page == 1){
                return 1
            }else{
                return page-1
            }
        },

        'next': function (page, total_pages) {
            if(page == total_pages){
                return total_pages
            }else{
                // console.log('this is page: ', page++)
                return page+1
            }
        }
    }

    const changePage = (button) =>{
        
        prev_page = current_page
        current_page = buttonOptions[button](current_page, total_pages)
        setRendering(!rendering)

    }

    const loadMoreNL = () =>{
        for (var j = mob_index+1; j < mob_index + 4; j++) {
           if(j < Nl_arr.length){
            display_arr.push(Nl_arr[j])
           }
        }   
        ordered_arr = display_arr
        mob_index = mob_index + 3
        setRendering(!rendering)
    }

    const searchNL = (evt) =>{
        var title
        var id
        filter_arr = []
        // JSON.parse(element.node.content)[0].arg1
        if(evt.target.value.length > 2){
            Nl_arr.forEach(element => {
                title = JSON.parse(element.node.content)[0].arg1
                title = title.toLowerCase()
                id = element.node.id
            
                if (title.indexOf(evt.target.value.toLowerCase()) > -1) {
                    filter_arr.push(element)            
                }                
            })
            ordered_arr = filter_arr
        }else{
            filter_arr = []
            ordered_arr = display_arr
        }
        setRendering(!rendering)
    }

    useEffect(() => {
        
        // display_arr = []
        if(display_arr.length == 0){
            
            for (var i = 0; i < Nl_arr.length; i++) {
                if(i<5){
                    display_arr.push(Nl_arr[i])
                    mob_index = i+1
                }
            }
            // display_arr.push(Nl_arr[0])
            // display_arr.push(Nl_arr[1])
            // display_arr.push(Nl_arr[2])
            // display_arr.push(Nl_arr[3])
            // display_arr.push(Nl_arr[4])
            // display_arr.push(Nl_arr[5])
            // mob_index = 5
        }
        ordered_arr = display_arr

        total_pages = parseInt(Nl_arr.length/3)
        if(Nl_arr.length%3 > 0){
            total_pages++
        }
        // console.log('total pages: ', total_pages)
       
        getNLImgId()
        getNLImages()
        setRendering(!rendering)
    }, [])

    return(
    
        <Bg>
           <h1 style={{color:'#333'}}>Newsletters</h1>
           <p>Here is the record of all previously sent newsletters</p>
           {Nl_arr[0].node.id != 0 ? (
               <>
                    <DesktopComp>
                        <DesktopDisplayComponent changePage={changePage} page={current_page} totalPages={total_pages} prevPage={prev_page} displayArr={Nl_arr} ImgId={nl_image_id} Images={nl_image_arr}/>
                    </DesktopComp>

                    <MobileComp>
                        <SearchComponent filterFcn={searchNL} displayMode={DisplayMode} changeMode={setDisplayMode}/>
                        <DisplayComponent loadMore={loadMoreNL} ImgId={nl_image_id} Images={nl_image_arr} displayArr={ordered_arr} MdisplayArr={ordered_arr} displayMode={DisplayMode}/>
                    </MobileComp>
                </>
            ) : (
                <div style={{height:"450px",padding:'100px'}}>
                    <h1 style={{color:'#888'}}>No Newsletters yet</h1>
                </div>
                )}
            <br></br>
            <br></br>
            <br></br>

        </Bg>   
       
    )
}

export default NlContentSec



// dns1.p02.nsone.net
// dns2.p02.nsone.net
// dns3.p02.nsone.net
// dns4.p02.nsone.net
